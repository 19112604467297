<script>
export default {
  name: 'BasePlayerBasic',
  props: {
    src: {
      type: String,
      default: ''
    },
    cover: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'lg'
    },
    classes: {
      type: String,
      default: 'h-[175px] lg:h-[437px]'
    }
  },
  data () {
    return {
      showButton: true,
      heightWrapper: '195'
    }
  },
  destroyed () {
    window.removeEventListener('resize', this.myEventHandler)
  },
  mounted () {
    window.addEventListener('resize', this.myEventHandler)
    this.$nextTick(() => {
      this.myEventHandler()
    })
  },
  methods: {
    myEventHandler () {
      this.heightWrapper = this.$refs[this.name].clientHeight
    },
    play (e) {
      const video = this.$refs[this.name]
      if (video.paused) {
        video.play()
        this.showButton = false
      } else {
        video.pause()
        this.showButton = true
      }
    }
  }
}
</script>

<template>
  <div
    class="video-basic"
    :class="'video-basic--' + size"
    @click="play($event)"
  >
    <video
      :ref="name"
      class="w-full rounded lg:rounded-lg"
      :class="classes"
      :poster="cover"
      :src="src"
    />
    <div
      v-if="showButton"
      :style="{'height': heightWrapper + 'px'}"
      class="video-basic__button-wrapper"
    >
      <div
        style="background: rgba(17,17,17, 0.32)"
        class="video-basic__play-button"
      >
        <BaseIcon
          name="play_arrow"
          color="white"
          :size="size === 'xs' ? 'lg-xl' : 'lg-2xl'"
        />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.video-basic {
  @apply relative rounded lg:rounded-lg;
  &__play-button {
    @apply absolute left-0 right-0 w-10 h-10 lg:w-20 lg:h-20 rounded-full top-[50%] translate-y-[-50%]
    mx-auto flex justify-center items-center cursor-pointer;
  }
  &__button-wrapper {
    @apply absolute top-0 left-0 w-full rounded lg:rounded-lg;
    background: rgba(0,0,0, 0.24);
  }
  &--xs {
    .video-basic__play-button {
      @apply w-10 h-10 lg:w-12 lg:h-12;
    }
  }
}
</style>
